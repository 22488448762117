import React, { useState } from "react";
import Axios from "axios";

const Register = () => {
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  Axios.defaults.withCredentials = true;

  const register = () => {
    Axios.post(`${backendUrl}/register`, {
      username: usernameReg,
      password: passwordReg,
    }).then((response) => {
      console.log(response);
    });
  };

  return (
    <>
      <div>
        <h1>Registrieren Sie sich</h1>
        <label htmlFor="username">E-mail</label>
        <input
          type="email"
          name="username"
          id=""
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
        />
        <label htmlFor="password"></label>
        <input
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
          type="password"
          name="password"
          id=""
        />
        <button className="" onClick={register}>
          Register
        </button>
      </div>
    </>
  );
};

export default Register;
