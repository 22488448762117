import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const [loginStatus, setLoginStatus] = useState(false);
  Axios.defaults.withCredentials = true;

  const login = () => {
    Axios.post(`${backendUrl}/login`, {
      username: username,
      password: password,
    }).then((response) => {
      if (!response.data.auth) {
        setLoginStatus(false);
        return setMessage(true);
      } else {
        setLoginStatus(true);
        localStorage.setItem("token", response.data.token);
        return navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    Axios.get(`${backendUrl}/login`).then((response) => {
      if (response.data.loggedIn == true) {
        setLoginStatus(response.data.user[0].username);
      }
    });
  });

  const userAuthenticated = () => {
    Axios.get(`${backendUrl}/isUserAuth`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    }).then((response) => {
      console.log(response);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <>
      <div
        className="h-screen w-screen flex justify-center items-center"
        style={{
          background:
            "linear-gradient(70deg, rgba(10, 40, 60, 0.5) , rgba(10, 40, 60, .9) ), url(images/ITC-Graf-GmbH.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <LoginForm onKeyDown={handleKeyDown}>
          {!loginStatus && (
            <div className="flex flex-col space-y-4">
              <div className="py-6 text-center">
                <img
                  className="h-12 mx-auto"
                  src="images/ITC_Mps_logo.png"
                  alt=""
                />
              </div>
              <TextField
                type="email"
                autoFocus
                style={{ color: "white" }}
                id="fullWidth email"
                label="E-Mail Adresse"
                variant="outlined"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />

              <TextField
                type="password"
                style={{ color: "white" }}
                id="fullWidth password"
                label="Passwort"
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              {/* Senden */}

              <Button onClick={login} variant="contained" size="large">
                Anmelden
              </Button>

              {loginStatus && (
                <Button variant="outlined" onClick={userAuthenticated}>
                  Verschlüsselung in Console anzeigen
                </Button>
              )}

              {/* Fehledermeldung */}
              <div>
                {message && (
                  <Alert severity="error">
                    Bitte überprüfen Sie Ihre Eingaben.
                  </Alert>
                )}
              </div>

              <p className="text-sm text-gray-500 text-center">
                Sie haben Fragen oder benötigen Hilfe?
                <br />
                <a
                  className="text-blue-500 underline"
                  href="mailto:d.kohout@itc-graf.de"
                >
                  Kontakt
                </a>
              </p>
            </div>
          )}
          {loginStatus && (
            <div className="space-y-2">
              <h1 className="text-base text-gray-700 text-center">
                Sie sind bereits angemeldet.
              </h1>
              <Button
                onClick={() => {
                  navigate("/dashboard");
                }}
                variant="outlined"
                className="w-full mt-4"
              >
                Zum Portal
              </Button>
            </div>
          )}
        </LoginForm>
      </div>
    </>
  );
};

const LoginForm = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  border: 1px solid rgb(229, 232, 236);
  padding: 1rem 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 25rem;
  max-width: 30rem;
`;

export default Login;
