import React, { useEffect, useState } from "react";
import Axios from "axios";
import AdminUi from "../components/AdminUi";
import UserUi from "../components/UserUi";
import { useNavigate } from "react-router-dom";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Dashboard = () => {
  const [role, setRole] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);
  const [username, setUsername] = useState("");

  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get(`${backendUrl}/login`).then((response) => {
      if (response.data.loggedIn == true) {
        setRole(response.data.user[0].role);
        setLoginStatus(true);
        setUsername(response.data.user[0].username);
        console.log(response, loginStatus, username);
      } else {
        return navigate("/") + console.log("nicht angemeldet!");
      }
    });
  }, []);
  return (
    <>
      {/* Hallo {username} */}
      {role == "admin" && <AdminUi />} {role == "visitor" && <UserUi />}
    </>
  );
};

export default Dashboard;
