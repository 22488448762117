import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import Button from "@mui/material/Button";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const UserUi = () => {
  Axios.defaults.withCredentials = true;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [role, setRole] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState();

  // Historie
  const [history, setHistory] = useState([]);

  // Daten
  const today = moment().format("DD-MM-YYYY");
  const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
  const kw = moment().isoWeek();

  // Wochentage
  const monday = moment().isoWeekday(1).format("DD-MM-YYYY");
  const tuesday = moment().isoWeekday(2).format("DD-MM-YYYY");
  const wednesday = moment().isoWeekday(3).format("DD-MM-YYYY");
  const thursday = moment().isoWeekday(4).format("DD-MM-YYYY");
  const friday = moment().isoWeekday(5).format("DD-MM-YYYY");
  const mondayNw = moment().isoWeekday(8).format("DD-MM-YYYY");
  const tuesdayNw = moment().isoWeekday(9).format("DD-MM-YYYY");

  // Buchungsplausibilität
  const [BPMon, setBPMon] = useState(false);
  const [BPTue, setBPTue] = useState(false);
  const [BPWed, setBPWed] = useState(false);
  const [BPThu, setBPThu] = useState(false);
  const [BPFri, setBPFri] = useState(false);

  // Verfügbarkeit von Essen
  const [AMMon, setAMMon] = useState(0);
  const [AMTue, setAMTue] = useState(0);
  const [AMWed, setAMWed] = useState(0);
  const [AMThu, setAMThu] = useState(0);
  const [AMFri, setAMFri] = useState(0);

  // Anzahl Essen
  const BreakfastMon = useRef(null);
  const MealMon = useRef(null);

  const BreakfastTue = useRef(null);
  const MealTue = useRef(null);

  const BreakfastWed = useRef(null);
  const MealWed = useRef(null);

  const BreakfastThu = useRef(null);
  const MealThu = useRef(null);

  const BreakfastFri = useRef(null);
  const MealFri = useRef(0);

  const [planUrl, setPlanUrl] = useState("");

  useEffect(() => {
    // Login
    async function FetchLoginData() {
      const request = await Axios.get(`${backendUrl}/login`).then(
        (response) => {
          if (response.data.loggedIn == true) {
            setRole(response.data.user[0].role);
            setUsername(response.data.user[0].username);
            setLoginStatus(true);
            setUserId(response.data.user[0].id);
          }
        }
      );
      return request;
    }
    const CheckAvailableMeals = async () => {
      const request = await Axios.post(`${backendUrl}/availablemeals`, {
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        mondayNw: mondayNw,
        tuesdayNw: tuesdayNw,
        kw: kw,
      }).then((response) => {
        setAMMon(60 - response.data[0].bookedRoomsMon);
        setAMTue(60 - response.data[0].bookedRoomsDie);
        setAMWed(60 - response.data[0].bookedRoomsMit);
        setAMThu(60 - response.data[0].bookedRoomsDon);
        setAMFri(60 - response.data[0].bookedRoomsFre);
      });
      return request;
    };

    const CalculateAvailableMeals = async () => {
      const request = await Axios.post(
        `${backendUrl}/calculateavailablemeals`,
        {
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          mondayNw: mondayNw,
          tuesdayNw: tuesdayNw,
        }
      ).then((response) => {
        console.log(response.data.monday);
      });
      return request;
    };

    FetchLoginData();
    CheckPossibility();
    CheckAvailableMeals();
    CalculateAvailableMeals();
  }, []);

  // Speiseplan abrufen
  const FetchMealPlan = async () => {
    const request = await Axios.post(`${backendUrl}/getplan`, { kw: kw });
    setPlanUrl(request.data[0].imgpath);
  };

  // Buchbarkeit prüfen
  const CheckPossibility = () => {
    const BookingPossibilityMon = () => {
      if (tomorrow >= monday) {
        setBPMon(false);
      } else {
        setBPMon(true);
      }
    };
    const BookingPossibilityTue = () => {
      if (tomorrow >= tuesday) {
        setBPTue(false);
      } else {
        setBPTue(true);
      }
    };
    const BookingPossibilityWed = () => {
      if (tomorrow >= wednesday) {
        setBPWed(false);
      } else {
        setBPWed(true);
      }
    };
    const BookingPossibilityThu = () => {
      if (tomorrow >= thursday) {
        setBPThu(false);
      } else {
        setBPThu(true);
      }
    };
    const BookingPossibilityFri = () => {
      if (tomorrow >= friday) {
        setBPFri(false);
      } else {
        setBPFri(true);
      }
    };
    BookingPossibilityMon();
    BookingPossibilityTue();
    BookingPossibilityWed();
    BookingPossibilityThu();
    BookingPossibilityFri();
  };

  // Historie
  const FetchHistory = async () => {
    const request = await Axios.post(`${backendUrl}/bookhistory`, {
      userId: userId,
    });
    setHistory(request.data);
    console.log(history);
  };

  // Buchen
  const BookMonday = () => {
    if (AMMon <= MealMon.current.value) {
      console.log("Nix mehr da!");
    } else {
      Axios.post(`${backendUrl}/bookmonday`, {
        monday: monday,
        userId: userId,
        bookedBreakfast: BreakfastMon.current.value,
        bookedMeal: MealMon.current.value,
      });
    }
  };

  const BookTuesday = () => {
    if (AMTue <= MealTue.current.value) {
      console.log("Nix mehr da!");
    } else {
      Axios.post(`${backendUrl}/booktuesday`, {
        tuesday: tuesday,
        userId: userId,
        bookedBreakfast: BreakfastTue.current.value,
        bookedMeal: MealTue.current.value,
      });
    }
  };

  const BookWednesday = () => {
    if (AMWed <= MealWed.current.value) {
      console.log("Nix mehr da!");
    } else {
      Axios.post(`${backendUrl}/bookwednesday`, {
        wednesday: wednesday,
        userId: userId,
        bookedBreakfast: BreakfastWed.current.value,
        bookedMeal: MealWed.current.value,
      });
    }
  };

  const BookThursday = () => {
    if (AMThu <= MealThu.current.value) {
      console.log("Nix mehr da!");
    } else {
      Axios.post(`${backendUrl}/bookthursday`, {
        thursday: thursday,
        userId: userId,
        bookedBreakfast: BreakfastThu.current.value,
        bookedMeal: MealThu.current.value,
      });
    }
  };

  const BookFriday = () => {
    if (AMFri <= MealFri.current.value) {
      console.log("Nix mehr da!");
    } else {
      Axios.post(`${backendUrl}/bookfriday`, {
        friday: friday,
        userId: userId,
        bookedBreakfast: BreakfastFri.current.value,
        bookedMeal: MealFri.current.value,
      });
    }
  };

  return (
    <>
      {/* <div className="max-w-xl mx-auto absolute z-50 h-full top-0 w-full">
        <Alert severity="success">
          <AlertTitle>Buchung erfolgreich</AlertTitle>
          This is a success alert — <strong>check it out!</strong>
        </Alert>
      </div> */}
      <div className="bg-white shadow p-5 relative">
        <div className="container mx-auto px-8 flex justify-between items-center">
          <img className="h-9" src="/images/ITC_Mps_logo.png" alt="" />
          <div className="bg-gray-100 py-2 px-4 text-gray-600 rounded-full cursor-pointer">
            {username} abmelden
          </div>
        </div>
      </div>
      <div className="bg-gray-50 h-screen">
        <div className="container mx-auto px-8">
          <div className="pt-16 pb-10">
            <p className="text-3xl text-slate-700">
              Angemeldet als: {username}
            </p>
            <p className="text-slate-700 max-w-xl mt-2">
              Willkommen im ITC Essensplanungs-Portal. Hier haben Sie die
              Möglichkeit Essen für die nächste Woche zu buchen.
            </p>
          </div>
          {/* Dropdown */}
          <div className="mt-4">
            <Accordion onClick={FetchMealPlan}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Aktueller Plan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Plan */}
                <a target="_blank" href={planUrl}>
                  <img
                    className="mx-auto p-8 w-full object-contain rounded-lg"
                    src={planUrl}
                    alt=""
                  />
                </a>
                <p className="text-sm text-center">
                  Klicken Sie auf den Plan, um diesen in Vollbild zu öffenen.
                </p>
              </AccordionDetails>
            </Accordion>
            {/* Essen History */}
            <Accordion onClick={FetchHistory}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Gebuchte Essen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {history.map((booking) => {
                    return (
                      <div
                        className="p-3 grid grid-cols-1 lg:grid-cols-4 items-center border-b"
                        key={booking.id}
                      >
                        <div className="text-md text-gray-600 font-bold">
                          {booking.bookingDate}
                        </div>
                        <div>
                          Gebuchte Essen: {""}
                          {booking.bookingMeal}
                        </div>
                        <div>
                          Gebuchte Brezeln: {""}
                          {booking.bookingBreakfast}
                        </div>
                        <div>
                          Buchungsnummer: {""}
                          {booking.id}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
            {/* Essen buchen */}
            {/* Montag */}
            {BPMon == true && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Montag {monday}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Verfügbar: {AMMon}
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Butterbrezel"
                      variant="outlined"
                      inputRef={BreakfastMon}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mittagessen"
                      variant="outlined"
                      inputRef={MealMon}
                    />
                    <Button onClick={BookMonday} variant="contained">
                      Speichern
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Dienstag */}
            {BPTue == true && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Dienstag {tuesday}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Butterbrezel"
                      variant="outlined"
                      inputRef={BreakfastTue}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mittagessen"
                      variant="outlined"
                      inputRef={MealTue}
                    />
                    <Button variant="contained" onClick={BookTuesday}>
                      Speichern
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Mittwoch */}
            {BPWed == true && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Mittwoch {wednesday}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Verfügbar: {AMWed}
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Butterbrezel"
                      variant="outlined"
                      inputRef={BreakfastWed}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mittagessen"
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 2 } }}
                      inputRef={MealWed}
                    />
                    <Button variant="contained" onClick={BookWednesday}>
                      Speichern
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Donnerstag */}
            {BPThu == true && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Donnerstag {thursday}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Butterbrezel"
                      variant="outlined"
                      inputRef={BreakfastThu}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mittagessen"
                      variant="outlined"
                      inputRef={MealThu}
                    />
                    <Button variant="contained" onClick={BookThursday}>
                      Speichern
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Freitag */}
            {BPFri == true && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Freitag {friday}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Butterbrezel"
                      variant="outlined"
                      inputRef={BreakfastFri}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mittagessen"
                      variant="outlined"
                      inputRef={MealFri}
                    />
                    <Button variant="contained" onClick={BookFriday}>
                      Speichern
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Montag (kommende Woche) {mondayNw}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Butterbrezel"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Mittagessen"
                    variant="outlined"
                  />
                  <Button variant="contained">Speichern</Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Dienstag (kommende Woche) {tuesdayNw}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Butterbrezel"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Mittagessen"
                    variant="outlined"
                  />
                  <Button variant="contained">Speichern</Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserUi;
