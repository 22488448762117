import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AdminUi = () => {
  // Axios & Cors
  Axios.defaults.withCredentials = true;

  // Kalenderwoche
  const kw0 = moment().isoWeek();
  const kw = parseInt(kw0 + 1);
  const kw1 = parseInt(kw + 1);
  const [role, setRole] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);
  const [username, setUsername] = useState("");

  // Dateimanagement
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Datei wählen");
  const [uploadedFile, setUploadedFile] = useState({});

  // Raumbuchungen
  const [bookedRoomsMon, setBookedRoomsMon] = useState("");
  const [bookedRoomsDie, setBookedRoomsDie] = useState("");
  const [bookedRoomsMit, setBookedRoomsMit] = useState("");
  const [bookedRoomsDon, setBookedRoomsDon] = useState("");
  const [bookedRoomsFre, setBookedRoomsFre] = useState("");

  // Wochentage
  const ersterMontag = moment().isoWeekday(8).format("DD-MM-YYYY");
  const ersterDienstag = moment().isoWeekday(9).format("DD-MM-YYYY");
  const ersterMittwoch = moment().isoWeekday(10).format("DD-MM-YYYY");
  const ersterDonnerstag = moment().isoWeekday(11).format("DD-MM-YYYY");
  const ersterFreitag = moment().isoWeekday(12).format("DD-MM-YYYY");

  // Feedback
  const [feedbackOfSave, setFeedbackOfSave] = useState(false);

  useEffect(() => {
    Axios.get(`${backendUrl}/login`).then((response) => {
      if (response.data.loggedIn == true) {
        setRole(response.data.user[0].role);
        setUsername(response.data.user[0].username);
        setLoginStatus(true);
        console.log(response, loginStatus, role);
      }
    });
  }, []);

  const handleChangeMon = (event) => {
    setBookedRoomsMon(event.target.value);
  };
  const handleChangeDie = (event) => {
    setBookedRoomsDie(event.target.value);
  };
  const handleChangeMit = (event) => {
    setBookedRoomsMit(event.target.value);
  };
  const handleChangeDon = (event) => {
    setBookedRoomsDon(event.target.value);
  };
  const handleChangeFre = (event) => {
    setBookedRoomsFre(event.target.value);
  };

  const setFileHandler = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const sendFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await Axios.post(`${backendUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { fileName, filePath } = res.data;
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      if (err.response.status === 500) {
        console.log("Serverfehler");
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  const savePlan = async () => {
    try {
      setFeedbackOfSave(true);
      console.log("HOE", feedbackOfSave);
      const res = await Axios.post(`${backendUrl}/saveplan`, {
        bookedRoomsMon: bookedRoomsMon * 15,
        bookedRoomsDie: bookedRoomsDie * 15,
        bookedRoomsMit: bookedRoomsMit * 15,
        bookedRoomsDon: bookedRoomsDon * 15,
        bookedRoomsFre: bookedRoomsFre * 15,
        kw: kw1,
        imgPath: uploadedFile.filePath,
      });
    } catch (err) {
      console.log("Fehler beim Speichern");
      setFeedbackOfSave(false);
    }
  };

  return (
    <>
      <div className="bg-gray-50 h-screen">
        <div className="container mx-auto px-8 py-24">
          <div>
            <p className="text-3xl text-slate-800">
              Angemeldet als: {username}
            </p>
            <p className="text-slate-700">ITC Administrationsbereich</p>
          </div>
          <div className="border rounded-lg shadow p-8 bg-white mt-5">
            <div>
              <h1 className="text-xl text-gray-800">Essensplanung KW {kw}</h1>
              <p className="text-gray-700 mb-8">Raumplanung</p>
              <div className="my-4"></div>
              <div className="grid lg:grid-cols-2 gap-8 items-center">
                <form onSubmit={sendFile} className=" flex gap-2">
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    type="file"
                    onChange={setFileHandler}
                  />

                  <Button
                    type="submit"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    size="large"
                  >
                    hochladen
                  </Button>
                </form>
                {uploadedFile ? (
                  <div>
                    <img
                      className="w-full h-full"
                      src={uploadedFile.filePath}
                      alt=""
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-4">
                <div className="flex items-center p-2">
                  <p className="mr-2 w-1/6">
                    Montag {ersterMontag} <br />
                    <span className="text-sm">
                      Gebucht: {bookedRoomsMon * 15}
                    </span>
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="bookedRoomsMon">Montag :</InputLabel>
                    <Select
                      value={bookedRoomsMon}
                      label="Montag"
                      onChange={handleChangeMon}
                    >
                      <MenuItem value={0}>Kein Raum belegt</MenuItem>
                      <MenuItem value={1}>1 Raum belegt</MenuItem>
                      <MenuItem value={2}>2 Räume belegt</MenuItem>
                      <MenuItem value={3}>3 Räume belegt</MenuItem>
                      <MenuItem value={4}>4 Räume belegt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex items-center p-2">
                  <p className="mr-2 w-1/6">
                    Dienstag {ersterDienstag} <br />
                    <span className="text-sm">
                      Gebucht: {bookedRoomsDie * 15}
                    </span>
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Dienstag :
                    </InputLabel>
                    <Select
                      value={bookedRoomsDie}
                      label="Dienstag"
                      onChange={handleChangeDie}
                    >
                      <MenuItem value={0}>Kein Raum belegt</MenuItem>
                      <MenuItem value={1}>1 Raum belegt</MenuItem>
                      <MenuItem value={2}>2 Räume belegt</MenuItem>
                      <MenuItem value={3}>3 Räume belegt</MenuItem>
                      <MenuItem value={4}>4 Räume belegt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex items-center p-2">
                  <p className="mr-2 w-1/6">
                    Mittwoch {ersterMittwoch} <br />
                    <span className="text-sm">
                      Gebucht: {bookedRoomsMit * 15}
                    </span>
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mittwoch :
                    </InputLabel>
                    <Select
                      value={bookedRoomsMit}
                      label="Mittwoch"
                      onChange={handleChangeMit}
                    >
                      <MenuItem value={0}>Kein Raum belegt</MenuItem>
                      <MenuItem value={1}>1 Raum belegt</MenuItem>
                      <MenuItem value={2}>2 Räume belegt</MenuItem>
                      <MenuItem value={3}>3 Räume belegt</MenuItem>
                      <MenuItem value={4}>4 Räume belegt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex items-center p-2">
                  <p className="mr-2 w-1/6">
                    Donnerstag {ersterDonnerstag} <br />
                    <span className="text-sm">
                      Gebucht: {bookedRoomsDon * 15}
                    </span>
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Donnerstag :
                    </InputLabel>
                    <Select
                      value={bookedRoomsDon}
                      label="Donnerstag"
                      onChange={handleChangeDon}
                    >
                      <MenuItem value={0}>Kein Raum belegt</MenuItem>
                      <MenuItem value={1}>1 Raum belegt</MenuItem>
                      <MenuItem value={2}>2 Räume belegt</MenuItem>
                      <MenuItem value={3}>3 Räume belegt</MenuItem>
                      <MenuItem value={4}>4 Räume belegt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex items-center p-2">
                  <p className="mr-2 w-1/6">
                    Freitag {ersterFreitag} <br />
                    <span className="text-sm">
                      Gebucht: {bookedRoomsFre * 15}
                    </span>
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Freitag :
                    </InputLabel>
                    <Select
                      value={bookedRoomsFre}
                      label="Freitag"
                      onChange={handleChangeFre}
                    >
                      <MenuItem value={0}>Kein Raum belegt</MenuItem>
                      <MenuItem value={1}>1 Raum belegt</MenuItem>
                      <MenuItem value={2}>2 Räume belegt</MenuItem>
                      <MenuItem value={3}>3 Räume belegt</MenuItem>
                      <MenuItem value={4}>4 Räume belegt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Button onClick={savePlan} variant="contained">
                Speichern
              </Button>
            </div>
            {feedbackOfSave == true && <p>Erfolgreich</p>}
            {feedbackOfSave == false && <div>Noch nicht gespeichert</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUi;
